import { BaseConfigType } from './default';

const Config: BaseConfigType = {
  baseDomain: 'alpha.zigzag.kr',
  apiBaseUrl: 'https://api.alpha.zigzag.kr/api/store',
  apiConsumerBaseUrl: 'https://api.alpha.zigzag.kr/api/2',
  appUrl: 'https://alpha.zigzag.kr',
  redirectAppUrl: 'https://store.alpha.zigzag.kr',
  contentUrl: 'https://content.alpha.zigzag.kr',
  assetPrefix: `https://alpha.zigzag.kr/resources/${process.env.ARCH}/${process.env.IMAGE_TAG}`,
  payApiBaseUrl: 'https://api.alpha.zigzag.kr/api/user-pay',
  maintenanceDataUrl: 'https://s3.ap-northeast-2.amazonaws.com/ec.s.alpha.zigzag.kr/status/maintenance.json',
  simplePayPassword:
    'https://api.alpha.zigzag.kr/api/security-keypad/static/js/simple_pay/simple_pay_password_alpha.js',
  simpleBankAppVersion: '6.5.0',
  sentry_dsn: 'https://baec8a7cec0f4385868e4d522aec72b3@o314572.ingest.sentry.io/4505390115913728',
  sentry_org: 'zigzag-production',
  business_juso_api_key: 'U01TX0FVVEgyMDIyMTEwNzEzMDEzMjExMzE5MjY=',
  hackle_key: 'ZTr2ywTsTgfUw2O62zTYoZsl99lMxlH4',
  zendesk_url: 'https://kakaostyle1647308466.zendesk.com/hc/p/requests-with-sso',
  /** @description marketing start*/
  airbridge_app: 'zigzagalpha',
  airbridge_web_token: '3abad58b1c0f47a1849b337b198b74c8',
  /** marketing end */
};

export default Config;
