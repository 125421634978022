import AlphaConfig from './alpha';
import { BaseConfigType } from './default';

const Config: BaseConfigType = {
  ...AlphaConfig,
  baseDomain: `zigzag-web-${process.env.NODE_ENV}.dev.zigzag.kr`,
  apiBaseUrl: `https://api-${process.env.NODE_ENV}.dev.zigzag.kr/api/store`,
  apiConsumerBaseUrl: `https://api-${process.env.NODE_ENV}.dev.zigzag.kr/api/2`,
  appUrl: `https://zigzag-web-${process.env.NODE_ENV}.dev.zigzag.kr`,
  assetPrefix: '',
  payApiBaseUrl: `https://api-${process.env.NODE_ENV}.dev.zigzag.kr/api/user-pay`,
  zigzag_postcode_url: `https://store-${process.env.NODE_ENV}.dev.zigzag.kr/postcode`,
  /** @description marketing start*/
  airbridge_app: 'zigzagdev',
  airbridge_web_token: '04a2742bcc6447a79ba5594b1c29fa0b',
  /** marketing end */
};

console.log(`>>> launch on ${process.env.NODE_ENV}<<<`);

export default Config;
