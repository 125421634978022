import { FC } from 'react';
import { ProductCard } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/tokens';

interface Props {
  onChange?: (isSelected: boolean) => void;
  isSelected: boolean;
}

export const ThumbnailCheckbox: FC<Props> = ({ isSelected, onChange }) => {
  return (
    <>
      {isSelected && <ProductCard.ThumbnailDimmed backgroundColor={vars.color.onImageBlackOpacity30} />}
      <ProductCard.ThumbnailCheckbox ariaLabel='상품 선택' checked={isSelected} onChange={onChange} />
    </>
  );
};
