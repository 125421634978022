import React from 'react';

import { isBrowser, isZigzag } from '@common/device-manager';
import { useBrowserEffect } from '@common/hooks';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

interface Ga4Uuid {
  uuid: string;
}

export const GoogleAnalytics: React.FC = () => {
  useBrowserEffect(() => {
    try {
      const uuid = localStorage.getItem('uuid');
      if (uuid) {
        const setUuidToDataLayer = (uuid: string) => {
          window.dataLayer = window.dataLayer || [{ uuid: '' }];
          ((window.dataLayer?.[0] || { uuid: '' }) as Ga4Uuid).uuid = uuid;
        };
        const setGa4UserId = (uuid: string) => {
          if (!isZigzag() && isBrowser()) {
            localStorage.setItem('uuid', uuid);
            // send
            setUuidToDataLayer(uuid);
          }
        };
        setGa4UserId(uuid);
      }
    } catch (err) {
      // ignore error
    }
  }, []);

  return null;
};
