import getConfig from 'next/config';

import { isZigzag } from '@common/device-manager';
import { getSiteIdFromHost, SiteId } from '@common/site-manager';

/**
 * 공개 런타임 구성을 검색합니다.
 * @returns 공개 런타임 구성입니다.
 */
export function getPublicRuntimeConfig() {
  const publicRuntimeConfig = getConfig().publicRuntimeConfig;
  return {
    ...publicRuntimeConfig.config,
    ...(publicRuntimeConfig.stage && { stage: publicRuntimeConfig.stage }),
    ...(publicRuntimeConfig.release && { release: publicRuntimeConfig.release }),
  };
}

/**
 * 런타임 구성에서 특정 키의 값을 검색합니다.
 * 값이 객체인 경우, 제공된 사이트 ID에 해당하는 값을 반환합니다.
 * 사이트 ID가 제공되지 않은 경우, 지그재그에 해당하는 값을 반환합니다.
 * @param key - 런타임 구성에서 검색할 값의 키입니다.
 * @param site_id - 선택 사항. 값을 검색할 사이트 ID입니다. 기본값은 지그재그입니다.
 * @param is_apps_store - 선택 사항. app/store 여부. 기본값은 store (통합체크아웃 내에서 store/website 구분 용도)
 *                      - 서버사이드에서 호출 시 필수
 * @returns 런타임 구성에서 제공된 키와 사이트 ID에 해당하는 값입니다.
 */
export function getRuntimeConfigValue(key: keyof BaseConfigType, site_id?: SiteId, is_apps_store?: boolean) {
  const config: BaseConfigType = getPublicRuntimeConfig();
  const value = config[key];

  if (typeof value === 'object') {
    // 통합체크아웃(apps/checkout) 에서 site_id 분기로직
    const target_site_id = site_id ?? getSiteIdFromHost(); // `site_id` 파라미터가 없으면, 호스트 정보로 사이트 ID 판단
    const target_is_apps_store = is_apps_store ?? isZigzag(); // `is_apps_store` 파라미터가 없으면, `isZigzag()` 함수로 판단

    const site_value = value[target_site_id];
    if (typeof site_value === 'object') {
      if (target_is_apps_store) {
        return site_value[1]; // apps/store
      } else {
        return site_value[2]; // apps/website
      }
    } else {
      return site_value;
    }
  } else {
    // 지그재그(apps/store, apps/website) 웹앱은 여기로 진입
    return value;
  }
}
