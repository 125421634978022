import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import type { ProductCardMetadataTitleProps } from '@croquiscom-pvt/zds';
import debounce from 'lodash/debounce';

import {
  HorizontalProductCard,
  HorizontalProductCardProps,
} from '../../../horizontal-product-card/HorizontalProductCard';
import { UxGoodsCardItemForHorizontalProductCard } from '../../../horizontal-product-card/types';
import { getHorizontalProductCardPropsByUxGoodsCardItemForHorizontalProductCard } from '../../../horizontal-product-card/utils';

export interface UseHorizontalProductCardWithItemOptions<T extends UxGoodsCardItemForHorizontalProductCard>
  extends Pick<ProductCardMetadataTitleProps, 'productNameLineClamp'>,
    Pick<HorizontalProductCardProps, 'likeButtonType' | 'thumbnailWidth' | 'thumbnailRatio' | 'metadataAlign'> {
  onImpression?: (item: T) => void;
  onClick?: (item: T, event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, item: T, event: React.MouseEvent<HTMLSpanElement>) => void;
}

export function useHorizontalProductCardWithItem<T extends UxGoodsCardItemForHorizontalProductCard>(
  item: T,
  options: UseHorizontalProductCardWithItemOptions<T>,
): Pick<
  React.ComponentPropsWithRef<typeof HorizontalProductCard>,
  | 'ref'
  | 'soldOut'
  | 'likeButtonType'
  | 'thumbnailWidth'
  | 'thumbnailRatio'
  | 'metadataAlign'
  | 'onClick'
  | 'onLikeClick'
> &
  ReturnType<typeof getHorizontalProductCardPropsByUxGoodsCardItemForHorizontalProductCard> {
  const {
    likeButtonType,
    thumbnailWidth,
    thumbnailRatio,
    metadataAlign,
    productNameLineClamp,
    onImpression,
    onClick,
    onLikeClick,
  } = options ?? {};

  const onImpressionRef = useRef(onImpression);
  const onClickRef = useRef(onClick);
  const onLikeClickRef = useRef(onLikeClick);

  const { ref } = useInView({
    onChange: debounce((inView) => {
      if (inView && onImpressionRef.current) {
        onImpressionRef.current(item);
      }
    }, 300),
    skip: !onImpression,
  });

  const common = useMemo(() => getHorizontalProductCardPropsByUxGoodsCardItemForHorizontalProductCard(item), [item]);

  if (productNameLineClamp !== undefined) {
    common.metadata.title.productNameLineClamp = productNameLineClamp;
  }

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (onClickRef.current) {
        onClickRef.current(item, event);
      }
    },
    [item],
  );

  const handleLikeClick = useCallback(
    (isLiked: boolean, event: React.MouseEvent<HTMLSpanElement>) => {
      if (onLikeClickRef.current) {
        onLikeClickRef.current(isLiked, item, event);
      }
    },
    [item],
  );

  useEffect(() => {
    onImpressionRef.current = onImpression;
  }, [onImpression]);

  useEffect(() => {
    onClickRef.current = onClick;
  }, [onClick]);

  useEffect(() => {
    onLikeClickRef.current = onLikeClick;
  }, [onLikeClick]);

  return {
    ref,
    likeButtonType,
    thumbnailWidth,
    thumbnailRatio,
    metadataAlign,
    ...common,
    onClick: handleClick,
    onLikeClick: handleLikeClick,
  };
}
